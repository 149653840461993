import React, {useRef, useState, useReducer} from 'react';
import Main from "../../modules/main/main";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {apiReports} from "../../services/api/reports";

function Reports(props) {
    let breadcrumbs = [
        {"name": "Reports", "active": true},
        // {"link": "/sfa", "name": "Create", "active": true},
    ];

    const [columns, setColumns] = useState([]);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Please enter a name.'),
        email: Yup.string().required('Please enter a email.'),
        password: Yup.string().optional(),
        passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match.'),
        role: Yup.string().required('Please select a role'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
        defaultValues: {
        }
    };
    const {
        register,
        handleSubmit,
        reset,
        formState
    } = useForm(formOptions);
    const { errors, isValid } = formState;

    const load_filters = (e) => {
        apiReports.getAll(`type=${e.target.value}`).then((col) => col).then((d) => setColumns( [d]));
        forceUpdate();
    };


    return (
        <Main title="Reports" breadcrumbs={breadcrumbs}>
            <div className="card card-primary">
                <div className="card-body">
                    <form className="form-horizontal">
                        <div>
                            <div className="form-group row">
                                <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Select Application</label>
                                <div className="col-sm-2">
                                    <select name="role" className={'form-control'} onChange={e => load_filters(e)} defaultValue="">
                                        <option value="" disabled>Select a Application</option>
                                        <option key="sfa" value="sfa">SFA</option>
                                        <option key="empower" value="empower">Empower</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div>
                        {
                            columns.map((tables) => {
                                Object.entries(tables).map(([key, value], index) =>
                                    {
                                        console.log(key);
                                        return (
                                            <div key={key}>
                                                {key}
                                                {
                                                    value.map((val, index) => {
                                                        return (
                                                            <div key={index}></div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </Main>

    );
}

export default Reports;